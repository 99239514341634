<template>
  <template v-if="agid">
    <mfa
      :app-retries="APP_RETRIES"
      :app-ws-url="APP_WS_URL"
      :app-saml-url="APP_SAML_URL"
      :otp-length="APP_OTP_LENGTH"
      :app-tenant="APP_TENANT"
      :app-service="APP_SERVICE"
      :app-refresh-qr-code="APP_REFRESH_QR_CODE"
      :base-url="APP_BASE_URL"
      :prop-orchestrator-url="APP_ORCHESTRATOR_URL"
    />
  </template>

  <template v-else>
    <sms-auth
      :locale="language"
      :retries="DATA_RETRIES"
      :sms-url="SMS_URL"
      :type="typeOtp"
    />
  </template>
</template>
<script>
import {useI18n} from "vue-i18n";
import {getCookie} from "@/utils/useCookie";
import {environment} from "@/config";

export default {
  name: 'Orchestrator',
  data() {
    return {
      language: this.locale,
      APP_RETRIES: Number(environment.VUE_APP_RETRIES),
      APP_WS_URL: environment.VUE_APP_WS_URL,
      APP_SAML_URL: environment.VUE_APP_AUTHORIZATION_URL,
      APP_OTP_LENGTH: Number(environment.VUE_APP_OTP_LENGTH),
      APP_TENANT: environment.VUE_APP_TENANT,
      APP_SERVICE: environment.VUE_APP_SERVICE,
      APP_REFRESH_QR_CODE: Number(environment.VUE_APP_REFRESH_QRCODE),
      APP_BASE_URL: environment.VUE_APP_BASE_URL,
      APP_ORCHESTRATOR_URL: environment.VUE_APP_ORCH_API,

      DATA_RETRIES: environment.VUE_APP_RETRIES,
      BASE_URL: environment.VUE_APP_ORCH_API,
      SMS_URL: getCookie('otp') === 'sms' ? '/sms-auth/api' : '/email-auth/api',

      typeOtp: getCookie('otp'),
    }
  },
  computed: {
    agid() {
      return process.env.VUE_APP_SUB_PATH !== "tim";
    }
  },
  setup() {
    const {t, locale} = useI18n()
    return {t, locale}
  },
  beforeMount(){
    console.log(this.APP_BASE_URL);
    console.log(this.APP_ORCHESTRATOR_URL);
  },
  mounted(){
    setInterval(() => {
      const $inputs = [...document.querySelectorAll("form .otp-input")];
      if(!$inputs) return;
      $inputs.forEach(item => {
        if(item.type ==="text"){
          item.type = "number"
        }
      });
    }, 1000);
  }
}
</script>
